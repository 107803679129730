// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    P9100430: ImageType,
    P9100438: ImageType,
    P9100443: ImageType,
    P9100444: ImageType,
    P9100446: ImageType,
    P9100451: ImageType,
    P9100467: ImageType,
    P9100468: ImageType,
    P9100469: ImageType,
    P9100482: ImageType,
    P9100486: ImageType,
    P9100489: ImageType,
    _DSF8288: ImageType,
    _DSF8289: ImageType,
    _DSF8296: ImageType,
    _DSF8298: ImageType,
    _DSF8299: ImageType,
    _DSF8304: ImageType,
    _DSF8312: ImageType,
    _DSF8328: ImageType,
    _DSF8335: ImageType,
  },
};

class Blog_2018_09_10_Subway extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3BIII" title="Das Boot" />
        <CanyonRatingCard rating="3BIV" title="The Subway" />
        <p>
          A friend who has never gone canyoneering before told me he put in for
          permits for The Subway. I told him if he got permits I'd love to go.
          Of course he will never get permits because <em>nobody</em> ever gets
          permits. Well, he got the permits!
        </p>
        <Image
          caption="Starting the hike down into Left Fork North Creek"
          image={data.P9100430}
        />
        <p>
          I convinced him to do Das Boot as well since he wanted a <em>real</em>{' '}
          canyoneering experience. It was lovely. For canyoneers who consider
          The Subway merely a hike, I'd definitely recommend it. It has lots of
          water so <GlossaryLink>wetsuits</GlossaryLink> are absolutely
          required.
        </p>
        <Image
          caption="Gearing up for the optional rappel into Das Boot"
          image={data.P9100438}
        />
        <Image caption="Pretty narrows in Das Boot" image={data.P9100443} />
        <Image caption="Pretty narrows in Das Boot" image={data.P9100444} />
        <Image
          caption={
            <>
              <GlossaryLink>Natural bridge</GlossaryLink>
              {' in Das Boot'}
            </>
          }
          image={data.P9100446}
        />
        <Image caption="Cool alcove in Das Boot" image={data.P9100451} />
        <p>
          We made quick work of the beautiful Das Boot, descended the final
          rappel, and arrived at the confluence with Russell Gulch. It was
          pretty obvious because the number of footprints went from one set of
          Canyoneer 3s to infinity of all varieties.
        </p>
        <Image
          caption="Another bridge - this one in The Subway"
          image={data.P9100467}
        />
        <Image
          caption="Same bridge looking the other way"
          image={data.P9100468}
        />
        <p>
          We weren't sure how much hiking we would have to do in our wetsuits
          and decided that if we started sweating we would remove the tops.
          However, we quickly got to the first pool so there was never a need.
        </p>
        <Image
          caption="The long straight part shortly before the goods"
          image={data.P9100469}
        />
        <Image caption="Iconic tree" image={data.P9100482} />
        <Image caption="Tree from the other side" image={data.P9100486} />
        <Image caption="Enjoying the canyon" image={data._DSF8288} />
        <Image caption="At the iconic titular section" image={data.P9100489} />
        <p>
          Once we arrived at the iconic subway section we had a quick lunch
          while waiting in line for our turn to rappel. Once down we hiked down
          canyon to the sunny section, removed and laid out our wetsuits, and I
          went back and spent a while taking pictures with my good camera.
        </p>
        <Image image={data._DSF8296} />
        <Image image={data._DSF8299} />
        <Image image={data._DSF8298} />
        <Image image={data._DSF8289} />
        <Image image={data._DSF8304} />
        <h2>People observations</h2>
        <ul>
          <li>People who carried helmets but never actually put them on.</li>
          <li>
            People learning how to rappel <em>at the rappel</em>
          </li>
          <li>
            No one brought wetsuits and therefore no one enjoyed the canyon
            because they were shivering too much
          </li>
          <li>People passing harnesses back and forth</li>
          <li>
            A couple singing all the way back on the hike to keep up spirits
          </li>
          <li>
            Several other hikers willing to take pictures of my group despite my
            terrible camera
          </li>
        </ul>
        <p>After I had had my fill we packed up and hiked out.</p>
        <Image
          caption="Little chute where much of the water was flowing"
          image={data._DSF8312}
        />
        <Image caption="Red waterfall" image={data._DSF8328} />
        <Image caption="Dinosaur tracks" image={data._DSF8335} />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-09-10-subway')(Blog_2018_09_10_Subway);

export const query = graphql`
  query {
    P9100430: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/P9100430.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P9100438: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/P9100438.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P9100443: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/P9100443.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P9100444: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/P9100444.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P9100446: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/P9100446.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P9100451: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/P9100451.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P9100467: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/P9100467.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P9100468: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/P9100468.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P9100469: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/P9100469.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P9100482: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/P9100482.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P9100486: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/P9100486.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P9100489: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/P9100489.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8288: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/_DSF8288.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8289: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/_DSF8289.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8296: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/_DSF8296.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8298: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/_DSF8298.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8299: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/_DSF8299.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8304: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/_DSF8304.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8312: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/_DSF8312.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8328: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/_DSF8328.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8335: file(
      relativePath: { eq: "images/blog/2018-09-10-subway/_DSF8335.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
